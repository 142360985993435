import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Navigation = styled.nav`
  display: none;
  text-transform: uppercase;
  margin-top: 7rem;

  li {
    a {
      &[aria-current='page'] {
        color: var(--orange);
      }
    }

    &:nth-child(3) {
      margin-bottom: 3rem;
    }
  }

  @media (min-width: 800px) {
    display: block;
  }
`;

const Line = styled.div`
  width: 18px;
  height: 2px;
  background-color: var(--orange);
  border-radius: 2px;
  margin-top: 5rem;
`;

const NavDesktop = () => (
  <Navigation>
    <ul>
      <li>
        <Link to="/architecture">architettura</Link>
      </li>
      <li>
        <Link to="/graphic">grafica</Link>
      </li>
      <li>
        <Link to="/models">modelli</Link>
      </li>
      <li>
        <Link to="/awards">premi</Link>
      </li>
      <li>
        <Link to="/about">about</Link>
      </li>
    </ul>
    <Line />
  </Navigation>
);

export default NavDesktop;
