import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';

const HeaderWrap = styled.div`
  position: relative;
  z-index: 2;
  max-width: 100rem;
  width: 85%;
  margin: 0 auto;
  margin-top: 5rem;
`;

const Logo = styled(Link)`
  text-transform: none;
  font-family: 'Source Serif Pro', serif;
  font-size: clamp(2.25rem, 5vw, 3rem);
  letter-spacing: 0.09em;

  &[aria-current='page'] {
    color: var(--orange);
  }
`;

const Navigation = () => (
  <HeaderWrap>
    <Logo to="/">mabastudio</Logo>
    <NavMobile />
    <NavDesktop />
  </HeaderWrap>
);

export default Navigation;
