import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FooterWrap = styled.footer`
  margin-top: 10rem;
  border-top: 1px solid var(--green-light);
`;

const FooterInner = styled.div`
  position: relative;
  max-width: 100rem;
  width: 85%;
  margin: 0 auto;
  padding-top: clamp(3rem, 8vw, 8rem);
  padding-bottom: clamp(7rem, 12vw, 17rem);
  font-size: clamp(1.3rem, 2vw, 1.5rem);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 3rem;
`;

const SpanStyle = styled.span`
  font-size: 1.2rem;
  position: absolute;
  bottom: 3rem;
`;

const Item = styled.li`
  margin-bottom: clamp(2.2rem, 2vw, 3rem);
`;

const Footer = () => (
  <FooterWrap>
    <FooterInner>
      <div>
        <span className="subtitle">sitemap</span>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/architecture">Architettura</Link>
          </li>
          <li>
            <Link to="/graphic">Grafica</Link>
          </li>
          <li>
            <Link to="/models">Modelli</Link>
          </li>
          <li>
            <Link to="/awards">Premi</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
        <SpanStyle>&copy; {new Date().getFullYear()} mabastudio</SpanStyle>
      </div>
      <div>
        <span className="subtitle">contatti</span>
        <ul>
          <Item>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/search/?api=1&query=mabastudio%2C+Via+Piazze+1%2C++Cenate+sopra+BG%2C+Italy"
            >
              Via Piazze, 1, <br /> Cenate Sopra, BG
            </a>
          </Item>
          <li>
            <a href="mailto:info@mabastudio.eu">info@mabastudio.eu</a>
          </li>
          <li>
            <a href="tel:(+39) 348 4451839">(+39) 348 4451839</a>
          </li>
          <li>p.iva 0443 073 0160</li>
        </ul>
        <SpanStyle>
          Site by{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/sofia-fateyev-82b585117/"
          >
            Sofia Fateyev
          </a>
        </SpanStyle>
      </div>
      <div>
        <span className="subtitle">social</span>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Mabastudio-architecture-101603771492116"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mabastudio"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </FooterInner>
  </FooterWrap>
);

export default Footer;
