import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import '../styles/fonts.css';

const Layout = ({ children }) => (
  <div>
    <GlobalStyles />
    <Typography />
    <Navigation />
    {children}
    <Footer />
  </div>
);

export default Layout;
