import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Facebook from '../assets/images/facebook.inline.svg';
import Instagram from '../assets/images/instagram.inline.svg';

const MenuBtn = styled.button`
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: var(--orange);
  text-transform: uppercase;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const MenuModal = styled.div`
  background-color: var(--dark);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 0 4rem;

  @media (min-width: 800px) {
    display: none;
  }
`;

const HeaderWrap = styled.div`
  position: relative;
  z-index: 2;
  width: 85%;
  margin: 0 auto;
`;

const Logo = styled(Link)`
  color: var(--orange);
  text-transform: none;
  font-family: 'Source Serif Pro', serif;
  font-size: clamp(2.25rem, 5vw, 3rem);
  letter-spacing: 0.09em;
`;

const Navigation = styled.ul`
  color: var(--orange);
  text-transform: uppercase;
  font-size: 3rem;
  width: 85%;
  margin: 0 auto;

  li {
    padding: 1rem 0;
    &:nth-child(3) {
      margin-bottom: 3rem;
    }
  }
`;

const Icons = styled.div`
  display: block;
  line-height: 0;
  display: flex;
  justify-content: flex-end;

  a {
    padding-left: 2rem;
    svg {
      path {
        fill: var(--orange);
      }
    }
  }
`;

const Contacts = styled.div`
  color: var(--orange);
  font-size: 2rem;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: 1rem;
  text-align: right;
  width: 85%;
  margin: 0 auto;
  font-weight: 400;

  li {
    line-height: 140%;
    padding: 1rem 0;
  }
`;

const NavMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.querySelector('html').style.overflow = isOpen
      ? 'hidden'
      : 'visible';
  }, [isOpen]);

  return (
    <>
      <MenuBtn onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}>
        {isOpen ? 'close' : 'menu'}
      </MenuBtn>
      {isOpen && (
        <MenuModal ariaHidden={isOpen && 'true'}>
          <HeaderWrap>
            <Logo to="/">mabastudio</Logo>
          </HeaderWrap>
          <Navigation>
            <li>
              <Link onClick={() => setIsOpen(false)} to="/architecture">
                architettura
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsOpen(false)} to="/graphic">
                grafica
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsOpen(false)} to="/models">
                modelli
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsOpen(false)} to="/awards">
                premi
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsOpen(false)} to="/about">
                about
              </Link>
            </li>
          </Navigation>
          <Contacts>
            <Icons>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/mabastudio"
              >
                <Instagram />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Mabastudio-architecture-101603771492116"
              >
                <Facebook />
              </a>
            </Icons>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/search/?api=1&query=mabastudio%2C+Via+Piazze+1%2C++Cenate+sopra+BG%2C+Italy"
                >
                  Via Piazze, 1, <br /> Cenate Sopra, BG
                </a>
              </li>
              <li>
                <a href="mailto:info@mabastudio.eu">info@mabastudio.eu</a>
              </li>
              <li>
                <a href="tel:(+39) 348 4451839">(+39) 348 4451839</a>
              </li>
            </ul>
          </Contacts>
        </MenuModal>
      )}
    </>
  );
};

export default NavMobile;
