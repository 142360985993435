import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

  html {
    font-family: 'Archivo', sans-serif;
    color: var(--green-dark);
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 300ms ease;
    
    &:hover{
      color: var(--orange);
    }
  }

  path { 
    transition: fill 300ms ease; 
  }

  .orange {
  display: inline-block;
  color: var(--orange);
  border-bottom: 2px solid var(--orange);
  cursor: pointer;
  font-size: clamp(1.4rem, 1.5vw, 1.8rem);
  font-weight: 500;
  transition: opacity 300ms ease;

  &:hover {
    opacity: 0.7;
  }
  }

  .subtitle{
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .bold {
    font-weight: 600;
  }
`;

export default Typography;
