import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark: #25302A;
  --green-dark: #37463E;
  --green-medium: #746e5a90;
  --green-light: #746e5a50;
  --orange: #F26C3E;
}

html {
  font-size: 62.5%;
}

body {
  font-size: clamp(1.4rem, 1.7vw, 1.6rem);
  line-height: clamp(2.2rem, 3vw, 2.8rem);
}

ul {
  list-style: none;
}
`;

export default GlobalStyles;
